<template>
  <v-card class="ma-0 pa-0" style="height: 170px">
    <v-card-text>
      <h3 class="title font-weight-regular">{{ $t('Revenue.MonthlyRevenue') }}</h3>
      <v-row class="mt-1">
        <v-progress-linear
          v-show="isLoading"
          color="#2196F3"
          indeterminate
          rounded
          height="8"
          style="width: calc(100% - 20px); margin-left: 10px; margin-top: 20px"
        ></v-progress-linear>
        <v-col v-show="!isLoading" cols="9">
          <span class="display-1 blue--text font-weight-regular">
            <v-icon class="blue--text" style="margin-top: -5px"
              >mdi-chart-areaspline</v-icon
            >
            {{ statistics.differentPercent }}%</span>
          {{ $t('Revenue.oflastmonthreached') }}
          <h6 class="subtitle-2 font-weight-regular mt-4">
            {{ $t('Revenue.TotalOf') }}
            <span class="blue--text">{{ statistics.amountThisMonth }}</span>
            {{ $t('Revenue.revenuethismonth') }}
          </h6>
        </v-col>
        <v-col v-show="!isLoading" cols="3">
          <div class="position-relative float-right mt-n4" style="height: 80px">
            <vue-apex-charts
              height="200px"
              width="150px"
              :options="SalesDifferenceData.chartOptions"
              :series="SalesDifferenceData.series"
            ></vue-apex-charts>
          </div>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import VueApexCharts from "vue-apexcharts";
import ReportService from "../../services/pas-report";
import { convertPriceString } from "../../plugins/helper";

export default {
  name: "TheSalesDifference",
  data: () => ({
    isLoading: true,
    statistics: {
      differentPercent: 0,
      amountLastMonth: 0,
      amountThisMonth: 0,
    },
    SalesDifferenceData: {
      series: [0],
      chartOptions: {
        chart: {
          type: "radialBar",
          offsetY: -20,
        },
        plotOptions: {
          radialBar: {
            startAngle: -110,
            endAngle: 110,
            dataLabels: {
              name: {
                show: false,
              },
              value: {
                offsetY: -2,
                fontSize: "24px",
                color: "#2196F3",
              },
            },
          },
        },
      },
    },
  }),
  components: {
    VueApexCharts,
  },
  created() {
    this.render();
  },
  methods: {
    async render() {
      this.isLoading = true;
      var result = await ReportService.getMonthlySaleIncreaseStatistics();
      this.isLoading = false;
      if (!result)
        this.$emit("onError", "Failed to load sale different statistics!");
      this.SalesDifferenceData.series = [Math.floor(result.percentIncrease)];
      this.statistics.amountLastMonth =
        convertPriceString(result.lastMonthValue) || "0 VND";
      this.statistics.amountThisMonth =
        convertPriceString(result.thisMonthValue) || "0 VND";
      this.statistics.differentPercent =
        Math.floor(result.percentIncrease) || "0";
      this.$forceUpdate();
    },
  },
};
</script>